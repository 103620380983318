import { createAction, createSlice } from '@reduxjs/toolkit'

import { initialStateDealsList as initialState } from '../resources'

const dfaDealsListSlice = createSlice({
    name: 'dfaDealsList',
    initialState,
    reducers: {
        setDfaDeals: (state, action) => {
            return { ...state, data: action.payload, isLoading: false, error: '' }
        },
        setDfaDealsLoading: (state) => {
            return { ...state, isLoading: true, data: [], error: '' }
        },
        setDfaDealsError: (state, action) => {
            return { ...state, error: action.payload, isLoading: false, data: [] }
        },
        setDfaDealsQuery: (state, action) => {
            return { ...state, ...action.payload }
        },
        clearDfaDeals: (state) => {
            state.isLoading = false
            state.filters = {}
            state.sort = {}
            state.range = {}
            state.data = []
            state.error = ''
        },
    },
})

export const getDfaDealsById = createAction<number>('dfaDealsList/getDfaDealsById')

export const {
    setDfaDeals,
    setDfaDealsLoading,
    setDfaDealsError,
    setDfaDealsQuery,
    clearDfaDeals,
} = dfaDealsListSlice.actions

export default dfaDealsListSlice.reducer
