import { ITransferDeal, UnionDealType } from '@dltru/dfa-models'
import { openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { authSelector } from '@store/auth/selectors'

import api from '@services/api'

import { setDfaDeals, setDfaDealsError, setDfaDealsLoading } from './index'
import { getDfaDealsById } from './index'

function* loadDfaDeals({ payload }: ReturnType<typeof getDfaDealsById>) {
    try {
        yield* put(setDfaDealsLoading())
        const userId = yield* select(authSelector.selectUserUid)
        const { data: emissionDealsData, error: emissionDealsError } = yield* call(
            api.lib.getDfaDealsByDfaId,
            payload,
        )

        if (emissionDealsError) {
            throw emissionDealsError
        }

        const emissionDeals =
            (emissionDealsData?.items as UnionDealType[])
                ?.filter((deal) => deal.user_id === userId)
                .map((deal) => ({ ...deal, type: 'emission' })) ?? []

        const { data: secondaryDealsData, error: secondaryDealsError } = yield* call(
            api.lib.getSecondaryDeals,
            { user_id: userId, asset_id: payload },
        )

        if (secondaryDealsError) {
            throw secondaryDealsError
        }

        const secondaryDeals =
            (secondaryDealsData?.items as UnionDealType[])?.map((deal) => ({
                ...deal,
                type: deal.sale_order?.user_id === userId ? 'sell' : 'buy',
            })) ?? []

        const { data: repaymentDealsData, error: repaymentDealsError } = yield* call(
            api.lib.getRepaymentDeals,
            { asset_id: payload }, // TODO user_id не отправляется т.к. хотелось бы получать погашения и по эмитенту
        )

        if (repaymentDealsError) {
            throw repaymentDealsError
        }

        const repaymentDeals =
            (repaymentDealsData?.items as UnionDealType[])
                ?.filter((deal) => deal?.emitter_id === userId || deal.user_id === userId)
                .map((deal) => ({ ...deal, type: 'repayment' })) ?? []

        const { data: transferOrdersData, error: transferOrdersError } = yield* call(
            api.lib.getTransferDfaDeals,
            {
                asset_id: payload,
                user_id: userId,
            },
        )

        if (transferOrdersError) {
            throw transferOrdersError
        }

        const transferOrders =
            (transferOrdersData.items as ITransferDeal[])
                ?.filter((deal) => deal.order.seller_asset_data.asset_id === Number(payload))
                .map((deal) => {
                    return {
                        ...deal,
                        type: deal.order?.seller_id === userId ? 'transfer_sell' : 'transfer_buy',
                    }
                }) ?? []

        const _data = [...repaymentDeals, ...secondaryDeals, ...transferOrders, ...emissionDeals]

        yield* put(setDfaDeals(_data))
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Не удалось получить информацию по сделкам',
        })
        yield* put(setDfaDealsError(error))
    }
}

export function* watchDfaDealsById(): Generator<StrictEffect> {
    yield* takeLatest(getDfaDealsById.type, loadDfaDeals)
}
