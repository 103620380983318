import { createAction, createSlice } from '@reduxjs/toolkit'

import { initialStateDealDetail as initialState } from '../resources'

const dfaDealDetailSlice = createSlice({
    name: 'dfaDetailDeal',
    initialState,
    reducers: {
        setDfaDeal: (state, action) => {
            return { ...state, ...action.payload, isLoading: false, error: '' }
        },
        setDfaDealLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setDfaDealError: (state, action) => {
            state.error = action.payload
        },
        dropDfaDeal: () => {
            return { ...initialState }
        },
    },
})

export const getDfaDealById = createAction<string>('dfaDetailDeal/getDfaDealById')

export const { setDfaDeal, setDfaDealLoading, setDfaDealError, dropDfaDeal } =
    dfaDealDetailSlice.actions

export default dfaDealDetailSlice.reducer
