import { createAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    original_amount_dfa: number
    rest_amount_dfa: number
    original_price: number
    rest_price: number
    isLoading: boolean
    error: string
}
export const initialState: IInitialState = {
    original_amount_dfa: 0,
    rest_amount_dfa: 0,
    original_price: 0,
    rest_price: 0,
    error: '',
    isLoading: false,
}

const dfaAvailableDealsSlice = createSlice({
    name: 'dfaAvailableDeals',
    initialState,
    reducers: {
        getDfaAvailableDeals: (state) => {
            return state
        },
        updateDfaAvailableDeals(state, action) {
            return { ...state, ...action.payload }
        },
    },
})

export const loadDfaAvailableDeals = createAction('dfaDetails/getDfaAvailableDeals')
export const { getDfaAvailableDeals, updateDfaAvailableDeals } = dfaAvailableDealsSlice.actions
export default dfaAvailableDealsSlice.reducer
