import { DfaStatusEnum, PriceSource } from '@dltru/dfa-models'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import api from '@services/api'

import { loadDfaAvailableDeals, updateDfaAvailableDeals } from './index'

function* handleGetDfaAvailableDeals() {
    try {
        yield* put(updateDfaAvailableDeals({ error: '', isLoading: true }))
        const dfaDetails = yield* select(dfaDetailsSelector.selectDfaDetails)
        if (!dfaDetails.id) {
            return
        }

        const { data: restAmount, error: restAmountError } = yield* call(
            api.lib.getDfaAvailableDeals,
            dfaDetails.id,
        )
        if (restAmountError || restAmount?.error) {
            throw restAmountError || restAmount?.error
        }
        let restPriceItem = {}
        if (
            dfaDetails.price_source_type === PriceSource.dynamic &&
            dfaDetails.status === DfaStatusEnum.collection_started
        ) {
            const { data: restPrice, error: restPriceError } = yield* call(
                api.lib.getDfaAvailableDealsV2,
                dfaDetails.id,
            )
            if (restPriceError || restPrice?.error) {
                throw restPriceError || restPrice?.error
            }
            restPriceItem = restPrice?.item ?? {}
        }

        yield* put(
            updateDfaAvailableDeals({
                error: '',
                isLoading: false,
                ...restAmount.item,
                ...restPriceItem,
            }),
        )
    } catch (error) {
        yield* put(updateDfaAvailableDeals({ error: 'available deals error', isLoading: false }))
    }
}

export function* watchGetDfaAvailableDeals(): Generator<StrictEffect> {
    yield* takeLatest(loadDfaAvailableDeals.type, handleGetDfaAvailableDeals)
}
