import { StrictEffect } from 'redux-saga/effects'
import { put, takeLatest } from 'typed-redux-saga'

import api from '@services/api'

import { setDfaDeal, setDfaDealError, setDfaDealLoading } from './index'
import { getDfaDealById } from './index'

function* loadDfaDeals({ payload }: ReturnType<typeof getDfaDealById>) {
    try {
        yield* put(setDfaDealLoading(true))
        yield* put(setDfaDealError(null))
        const { data, error } = yield api.lib.getDfaDealById(payload)
        if (error) {
            throw error
        }

        yield* put(setDfaDeal(data))
    } catch (error) {
        yield* put(setDfaDealLoading(false))
        yield* put(setDfaDealError(error))
    }
}

export function* watchGetDfaDealById(): Generator<StrictEffect> {
    yield* takeLatest(getDfaDealById.type, loadDfaDeals)
}
